import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Grid,
  TextField,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  FormHelperText,
  InputAdornment,
} from "@mui/material";
import styles from "./BillingAddressFormGuest.module.css"; // Import CSS module
import { useNavigate } from "react-router-dom";

const BillingAddressFormGuest = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    company: "",
    address1: "",
    address2: "",
    city: "",
    postCode: "",
    country: "",
    state: "",
    mobile: "",
    phoneCode: "",
  });

  const [addressData, setAddressData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [errors, setErrors] = useState({});
  const [showForm, setShowForm] = useState(false); // State to manage form visibility

  useEffect(() => {
    fetchAddressData();
    fetchCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchStates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.country]);

  // Fetch address data from localStorage on component mount
  const fetchAddressData = () => {
    const storedAddress = sessionStorage.getItem("billingDetails");
    if (storedAddress) {
      const parsedAddress = JSON.parse(storedAddress);
      setAddressData(parsedAddress);
      // Populate formData with existing address if editing
      if (isEditing) {
        setFormData(parsedAddress);
      }
    }
  };

  // Fetch countries from API
  const fetchCountries = async () => {
    try {
      const response = await axios.get(
        "https://www.backend.luxurybubblebasket.com/countries"
      );
      setCountries(response.data.data);
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };

  // Fetch states based on selected country
  const fetchStates = async () => {
    if (formData.country) {
      try {
        const response = await axios.get(
          `https://www.backend.luxurybubblebasket.com/statesByCountryCode?countryCode=${formData.country}`
        );
        setStates(response.data.data);
      } catch (error) {
        console.error("Error fetching states:", error);
      }
    } else {
      setStates([]);
    }
  };

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle country selection change
  const handleCountryChange = (e) => {
    const selectedCountry = countries.find(
      (country) => country.isoCode === e.target.value
    );
    if (selectedCountry) {
      setFormData({
        ...formData,
        country: selectedCountry.isoCode,
        phoneCode: selectedCountry.phonecode,
        state: "",
      });
      setStates([]);
    } else {
      setFormData({
        ...formData,
        country: "",
        phoneCode: "",
        state: "",
      });
      setStates([]);
    }
  };

  // Form validation
  const validateForm = () => {
    const newErrors = {};
    if (!formData.firstName) newErrors.firstName = "First name is required";
    if (!formData.lastName) newErrors.lastName = "Last name is required";
    if (!formData.address1) newErrors.address1 = "Address Line 1 is required";
    if (!formData.city) newErrors.city = "City is required";
    if (!formData.postCode) newErrors.postCode = "Post code is required";
    if (!formData.country) newErrors.country = "Country is required";
    if (!formData.state) newErrors.state = "State is required";
    if (!formData.mobile) newErrors.mobile = "Mobile number is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (validateForm()) {
      const dataToSubmit = {
        id: addressData ? addressData.id : Date.now().toString(),
        firstName: formData.firstName,
        lastName: formData.lastName,
        company: formData.company,
        address1: formData.address1,
        address2: formData.address2,
        city: formData.city,
        postCode: formData.postCode,
        country: formData.country,
        state: formData.state,
        mobile: formData.mobile,
        phoneCode: formData.phoneCode,
      };

      // Save to localStorage
      sessionStorage.setItem("billingDetails", JSON.stringify(dataToSubmit));
      setAddressData(dataToSubmit);
      resetForm();
      setShowForm(false); // Hide the form after submission
      alert(
        isEditing
          ? "Address updated successfully"
          : "Address added successfully"
      );
    }
  };

  // Reset form fields and state
  const resetForm = () => {
    setFormData({
      firstName: "",
      lastName: "",
      company: "",
      address1: "",
      address2: "",
      city: "",
      postCode: "",
      country: "",
      state: "",
      mobile: "",
      phoneCode: "",
    });
    setIsEditing(false);
    setErrors({});
  };

  // Handle edit button click
  const handleEdit = () => {
    if (addressData) {
      setFormData(addressData);
      setIsEditing(true);
      setShowForm(true); // Show the form for editing
    }
  };

  // Handle adding a new address
  const handleAddNewAddress = () => {
    resetForm(); // Reset form for new address
    setIsEditing(false); // Set editing to false for adding a new address
    setShowForm(true); // Show the form for adding a new address
  };

  // Handle cancel button click
  const handleCancel = () => {
    resetForm(); // Reset form
    setShowForm(false); // Hide the form
  };

  return (
    <div className={styles.container}>
      {!showForm && (
        <>
          <h2 className={styles.formTitle}>
            Billing Address{" "}
            <div className={styles.buttonContainer}>
              {addressData ? (
                <p
                  variant="contained"
                  color="primary"
                  onClick={handleEdit}
                  className={styles.editButton}
                >
                  Edit Address
                </p>
              ) : (
                <p
                  variant="contained"
                  color="primary"
                  onClick={handleAddNewAddress}
                  className={styles.addButton}
                >
                  Add Address
                </p>
              )}
            </div>
          </h2>
          {addressData ? (
            <div className={styles.addressDetails}>
              <p>
                {" "}
                {addressData.firstName} {addressData.lastName}
              </p>
              <p>{addressData.company && addressData.company}</p>
              <p>{addressData.address1}</p>
              <p>{addressData.address2}</p>
              <p> {addressData.city}</p>
              <p>{addressData.state}</p>
              <p> {addressData.postCode}</p>
              <p>
                {countries.find(
                  (country) => country.isoCode === addressData.country
                )?.name || addressData.country}
              </p>
              <p>
                {" "}
                +{addressData.phoneCode} {addressData.mobile}
              </p>
            </div>
          ) : (
            <div className={styles.noAddress}>
              <p>No billing address found. Please add one.</p>
            </div>
          )}
        </>
      )}

      {showForm && (
        <form className={styles.form}>
          <Grid container spacing={2}>
            {/* First Name */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="First Name"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                error={!!errors.firstName}
                helperText={errors.firstName}
                required
                className={styles.formField}
              />
            </Grid>
            {/* Last Name */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Last Name"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                error={!!errors.lastName}
                helperText={errors.lastName}
                required
                className={styles.formField}
              />
            </Grid>
            {/* Company */}
            <Grid item xs={12}>
              <TextField
                label="Company"
                name="company"
                value={formData.company}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                className={styles.formField}
              />
            </Grid>
            {/* Address Line 1 */}
            <Grid item xs={12}>
              <TextField
                label="Address Line 1"
                name="address1"
                value={formData.address1}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                error={!!errors.address1}
                helperText={errors.address1}
                required
                className={styles.formField}
              />
            </Grid>
            {/* Address Line 2 */}
            <Grid item xs={12}>
              <TextField
                label="Address Line 2"
                name="address2"
                value={formData.address2}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                className={styles.formField}
              />
            </Grid>
            {/* City */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="City"
                name="city"
                value={formData.city}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                error={!!errors.city}
                helperText={errors.city}
                required
                className={styles.formField}
              />
            </Grid>
            {/* Post Code */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Post Code"
                name="postCode"
                value={formData.postCode}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                error={!!errors.postCode}
                helperText={errors.postCode}
                required
                className={styles.formField}
              />
            </Grid>
            {/* Country */}
            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
                variant="outlined"
                error={!!errors.country}
                className={styles.formField}
              >
                <InputLabel>Country *</InputLabel>
                <Select
                  name="country"
                  value={formData.country}
                  onChange={handleCountryChange}
                  label="Country"
                  required
                >
                  {countries.map((country) => (
                    <MenuItem key={country.isoCode} value={country.isoCode}>
                      {country.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{errors.country}</FormHelperText>
              </FormControl>
            </Grid>
            {/* State */}
            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
                variant="outlined"
                error={!!errors.state}
                className={styles.formField}
                required
              >
                <InputLabel>State</InputLabel>
                <Select
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                  label="State"
                  required
                >
                  {states.map((state) => (
                    <MenuItem key={state.isoCode} value={state.isoCode}>
                      {state.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{errors.state}</FormHelperText>
              </FormControl>
            </Grid>
            {/* Mobile */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Mobile"
                name="mobile"
                type="text" // Change type to 'text' to handle the maxLength properly
                value={formData.mobile}
                onChange={(e) => {
                  const value = e.target.value;
                  // Allow digits, dashes, spaces, and parentheses
                  if (/^[\d\-()\s]*$/.test(value) && value.length <= 15) {
                    handleChange(e); // Call the change handler only if the input is valid
                  }
                }}
                variant="outlined"
                fullWidth
                error={!!errors.mobile}
                helperText={errors.mobile}
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      +{formData.phoneCode || ""}
                    </InputAdornment>
                  ),
                }}
                className={styles.formField}
              />
            </Grid>
            {/* Buttons */}
            <Grid item xs={12} className={styles.buttonContainer}>
              <Button
                onClick={(e) => handleSubmit(e)}
                variant="contained"
                color="primary"
                className={styles.submitButton}
              >
                {isEditing ? "Update Address" : "Save Address"}
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleCancel}
                className={styles.cancelButton}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </div>
  );
};

export default BillingAddressFormGuest;
