// src/PaymentGuest.js
import React, { useState, useEffect } from "react";
import browserInfo from "@smartbear/browser-info";
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  Alert,
  Paper,
  Grid,
} from "@mui/material";
import style from "./PaymentPageGuest.module.css";

function PaymentGuest() {
  const [loaded, setLoaded] = useState(false);
  const [squarePayments, setSquarePayments] = useState(undefined);
  const [squareCard, setSquareCard] = useState(undefined);
  const [applePay, setApplePay] = useState(undefined);
  const [isSubmitting, setSubmitting] = useState(false);
  const [checkoutData, setCheckoutData] = useState({
    productsData: [],
    subtotal: 0,
    tax: 0,
    totalPrice: 0,
  });
  const [shippingDetails, setShippingDetails] = useState({});
  const [validFields, setValidFields] = useState({
    cardNumber: false,
    cvv: false,
    expirationDate: false,
    postalCode: false,
  });
  const [promoCode, setPromoCode] = useState("");
  const [couponDiscount, setCouponDiscount] = useState(0);
  const [cartData, setCartData] = useState([]);
  const [initializing, setInitializing] = useState(true);
  const [initError, setInitError] = useState(null);

  // Constants for Square Payment
  const APPLICATION_ID = "sq0idp-lLdmMI7HdC3fe-aQXIyWSA"; // Use your real application ID
  const LOCATION_ID = "LTAKQ17YDNMB6"; // Use your real location ID

  // Tax rate (6%)
  const TAX_RATE = 0.06;

  useEffect(() => {
    // Retrieve data from session storage
    const storedPromoCode = sessionStorage.getItem("promoCode") || "";
    const storedCouponDiscount = parseFloat(
      sessionStorage.getItem("couponDiscount")
    ) || 0;
    const storedCartData =
      JSON.parse(sessionStorage.getItem("cartData")) || [];

    setPromoCode(storedPromoCode);
    setCouponDiscount(storedCouponDiscount);
    setCartData(storedCartData);

    // Calculate subtotal
    const subtotal = storedCartData.reduce(
      (acc, item) => acc + item.price * item.quantity,
      0
    );

    // Calculate tax
    const tax = parseFloat((subtotal * TAX_RATE).toFixed(2));

    // Calculate total price
    const totalPrice = parseFloat((subtotal + tax - storedCouponDiscount).toFixed(2));

    setCheckoutData({
      productsData: storedCartData,
      subtotal,
      tax,
      totalPrice,
    });

    // Load Square's Payment SDK
    const existingScript = document.getElementById("webPayment");
    if (existingScript) {
      setLoaded(true);
      setInitializing(false);
    } else {
      const script = document.createElement("script");
      script.src = "https://web.squarecdn.com/v1/square.js";
      script.id = "webPayment";
      document.body.appendChild(script);
      script.onload = () => {
        setLoaded(true);
        setInitializing(false);
      };
      script.onerror = () => {
        console.error("Failed to load Square.js");
        setInitError(
          "Failed to load payment gateway. Please try again later."
        );
        setInitializing(false);
      };
    }
  }, []);

  // Initialize Square Payments
  useEffect(() => {
    if (loaded && !squarePayments) {
      if (!window?.Square) {
        console.error("Square.js failed to load properly");
        setInitError(
          "Payment gateway is unavailable. Please try again later."
        );
        return;
      }
      try {
        const paymentsInstance = window.Square.payments(
          APPLICATION_ID,
          LOCATION_ID
        );
        setSquarePayments(paymentsInstance);
      } catch (error) {
        console.error("Error initializing Square Payments:", error);
        setInitError("Payment gateway initialization failed.");
      }
    }
  }, [loaded, squarePayments]);

  // Initialize Square Card
  useEffect(() => {
    if (squarePayments && !squareCard) {
      squarePayments
        .card()
        .then((card) => {
          setSquareCard(card);
          attachCard(card);
        })
        .catch((error) => {
          console.error("Square Card initialization failed", error);
          setInitError("Failed to initialize payment methods.");
        });
    }

    return () => {
      if (squareCard) {
        squareCard.destroy();
        setSquareCard(undefined);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [squarePayments]);

  // Initialize Apple Pay
  useEffect(() => {
    if (squarePayments && !applePay && browserInfo.name === "Safari") {
      initializeApplePay();
    }

    return () => {
      if (applePay) {
        applePay.destroy();
        setApplePay(undefined);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [squarePayments, applePay]);

  /**
   * Tokenizes the payment method to obtain a nonce
   * @param {Object} paymentMethod - The payment method object
   * @returns {String} - The payment nonce
   */
  const tokenizePaymentMethod = async (paymentMethod) => {
    try {
      const tokenResult = await paymentMethod.tokenize();
      if (tokenResult.status === "OK") {
        return tokenResult.token;
      }
      let errorMessage = `Tokenization failed - status: ${tokenResult.status}`;
      if (tokenResult.errors) {
        errorMessage += ` and errors: ${JSON.stringify(
          tokenResult.errors
        )}`;
      }
      throw new Error(errorMessage);
    } catch (error) {
      console.error("Tokenization Error:", error);
      throw error;
    }
  };

  /**
   * Handles the submission of the payment method
   * @param {Object} paymentMethod - The payment method object
   */
  const handlePaymentMethodSubmission = async (paymentMethod) => {
    const isCardFieldsValid = Object.values(validFields).every((v) => v);
    if (!isCardFieldsValid) {
      alert("Please fill in all payment fields correctly.");
      return;
    }

    if (!paymentMethod) {
      alert("Payment method is not available.");
      return;
    }

    if (!isSubmitting) {
      setSubmitting(true);
      try {
        const nonce = await tokenizePaymentMethod(paymentMethod);
        console.log("Payment Nonce:", nonce);
        // TODO: Replace with actual backend API call
        const mockResponse = {
          status: 200,
          data: {
            orderId: "ORDER123456",
            amount: checkoutData.totalPrice,
            status: "COMPLETED",
          },
        };

        // Simulate network delay
        await new Promise((resolve) => setTimeout(resolve, 2000));

        if (mockResponse.status === 200 || mockResponse.status === 201) {
          alert("Payment successful!");
          sessionStorage.setItem(
            "orderData",
            JSON.stringify(mockResponse.data)
          );
          sessionStorage.removeItem("cartData");
          sessionStorage.removeItem("promoCode");
          sessionStorage.removeItem("couponDiscount");
          sessionStorage.removeItem("totalPrice");
          window.location.href = "/"; // Redirect to home page
        } else {
          throw new Error("Payment failed due to server error.");
        }
      } catch (error) {
        console.error("Payment FAILURE", error);
        if (error.response) {
          console.error("Response data:", error.response.data);
          console.error("Response status:", error.response.status);
          alert(
            `Payment failed! ${
              error.response.data.message || "Please try again."
            }`
          );
        } else if (error.request) {
          console.error("Request data:", error.request);
          alert("Payment failed! No response from server.");
        } else {
          console.error("Error message:", error.message);
          alert(`Payment failed! ${error.message}`);
        }
      } finally {
        setSubmitting(false);
      }
    }
  };

  /**
   * Handles card field events to validate inputs
   * @param {Object} event - The event object
   */
  const handleCardEvents = ({ detail }) => {
    if (detail) {
      const { currentState, field } = detail;
      const isCompletelyValid = currentState?.isCompletelyValid;
      if (field && typeof isCompletelyValid === "boolean") {
        setValidFields((prevState) => ({
          ...prevState,
          [field]: isCompletelyValid,
        }));
      }
    }
  };

  /**
   * Initializes Apple Pay if available
   */
  const initializeApplePay = async () => {
    try {
      const paymentRequest = squarePayments.paymentRequest(paymentRequestMock);
      const aPay = await squarePayments.applePay(paymentRequest);
      setApplePay(aPay);
    } catch (error) {
      console.error("Apple Pay initialization failed", error);
    }
  };

  /**
   * Attaches the Square Card component to the DOM and sets up event listeners
   * @param {Object} card - The Square Card object
   */
  const attachCard = (card) => {
    if (card) {
      card.attach("#card-container");
      card.addEventListener("submit", () =>
        handlePaymentMethodSubmission(card)
      );
      card.addEventListener("focusClassAdded", handleCardEvents);
      card.addEventListener("focusClassRemoved", handleCardEvents);
      card.addEventListener("errorClassAdded", handleCardEvents);
      card.addEventListener("errorClassRemoved", handleCardEvents);
      card.addEventListener("cardBrandChanged", handleCardEvents);
      card.addEventListener("postalCodeChanged", handleCardEvents);
    }
  };

  /**
   * Defines the payment request object for Square Payment
   */
  const paymentRequestMock = {
    countryCode: "US",
    currencyCode: "USD",
    lineItems: [
      ...checkoutData.productsData.map((product) => ({
        amount: (product.price * product.quantity).toFixed(2),
        label: `${product.title} x${product.quantity}`,
        pending: false,
      })),
      {
        amount: checkoutData.tax.toFixed(2),
        label: "Tax (6%)",
        pending: false,
      },
      {
        amount: couponDiscount > 0 ? `-${couponDiscount.toFixed(2)}` : "0.00",
        label: "Coupon Discount",
        pending: false,
      },
    ],
    requestBillingContact: false, // Not using billing details
    requestShippingContact: false, // Not using shipping details
    total: {
      amount: checkoutData.totalPrice
        ? checkoutData.totalPrice.toFixed(2)
        : "0.00",
      label: "Total",
      pending: false,
    },
  };

  // Render loading state
  if (initializing) {
    return (
      <Box sx={{ padding: 3, textAlign: "center" }}>
        <CircularProgress />
        <Typography>Initializing payment gateway...</Typography>
      </Box>
    );
  }

  // Render initialization error
  if (initError) {
    return (
      <Box sx={{ padding: 3 }}>
        <Alert severity="error">{initError}</Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: 3, maxWidth: 800, margin: "auto" }}>
      <Paper elevation={3} sx={{ padding: 4 }}>
        <Typography variant="h4" align="center" gutterBottom>
          Guest Checkout
        </Typography>

        <Grid container spacing={2}>
          {/* Order Summary Section */}
          <Grid item xs={12} md={6}>
            <div className={style.orderSummary}>
              <Typography variant="h5" gutterBottom>
                Order Summary
              </Typography>
              <div className={style.cartItems}>
                {checkoutData.productsData.length > 0 ? (
                  checkoutData.productsData.map((item, index) => (
                    <div key={index} className={style.cartItem}>
                      <span>
                        {item.title} x {item.quantity}
                      </span>
                      <span>${(item.price * item.quantity).toFixed(2)}</span>
                    </div>
                  ))
                ) : (
                  <Typography>No items in the cart.</Typography>
                )}
              </div>
              {/* Summary Details */}
              <div className={style.summaryDetails}>
                <div className={style.detailItem}>
                  <span>Subtotal:</span>
                  <span>${checkoutData.subtotal.toFixed(2)}</span>
                </div>
                <div className={style.detailItem}>
                  <span>Tax (6%):</span>
                  <span>${checkoutData.tax.toFixed(2)}</span>
                </div>
                <div className={style.detailItem}>
                  <span>Coupon Discount:</span>
                  <span>
                    {couponDiscount > 0
                      ? `-$${couponDiscount.toFixed(2)}`
                      : "N/A"}
                  </span>
                </div>
                <div className={style.detailItem}>
                  <strong>
                    <span>Total Amount:</span>
                  </strong>
                  <strong>
                    <span>${checkoutData.totalPrice.toFixed(2)}</span>
                  </strong>
                </div>
              </div>
            </div>
          </Grid>

          {/* Payment Details Section */}
          <Grid item xs={12} md={6}>
            <Typography variant="h6">Payment Details</Typography>
            <Box id="card-container" sx={{ marginTop: 2 }} />
            {applePay && (
              <Box sx={{ marginTop: 2 }}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handlePaymentMethodSubmission(applePay)}
                  disabled={isSubmitting}
                  fullWidth
                >
                  Pay with Apple Pay
                </Button>
              </Box>
            )}
          </Grid>
        </Grid>

        {/* Pay Now Button */}
        <Box sx={{ marginTop: 3, textAlign: "center" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handlePaymentMethodSubmission(squareCard)}
            disabled={
              !Object.values(validFields).every((v) => v) || isSubmitting
            }
            startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
            fullWidth
          >
            {isSubmitting ? "Processing..." : "Pay Now"}
          </Button>
        </Box>

        {/* Processing Alert */}
        {isSubmitting && (
          <Alert severity="info" sx={{ marginTop: 2 }}>
            Processing your payment, please wait...
          </Alert>
        )}
      </Paper>
    </Box>
  );
}

export default PaymentGuest;
