import React, { useEffect, useState } from "react";
import style from "./CheckoutPageSectionSecondGuest.module.css";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import BillingAddressFormGuest from "../../LoginPageSection/LoginPageSectionSecond/BillingAddressFormGuest/BillingAddressFormGuest";
import ShippingAddressFormGuest from "../../LoginPageSection/LoginPageSectionSecond/ShippingAddressFormGuest/ShippingAddressFormGuest";

function CheckoutPageSectionSecondGuest() {
  const [showCouponField, setShowCouponField] = useState(false);
  const [couponError, setCouponError] = useState("");
  const [coupon, setCoupon] = useState("");
  const [shippingDetails, setShippingDetails] = useState([]);
  const [selectedAddressId, setSelectedAddressId] = useState("");
  const [isProceedEnabled, setIsProceedEnabled] = useState(false);
  const [promoDiscount, setPromoDiscount] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0); // Updated total price after discount
  const cartData = JSON.parse(sessionStorage.getItem("cartData")) || [];
  const navigate = useNavigate();

  useEffect(() => {
    fetchShippingDetails();
  }, []);

  useEffect(() => {
    sessionStorage.setItem("checkoutStatus", JSON.stringify(false));
  }, []);

  const fetchShippingDetails = async () => {
    try {
      const token = Cookies.get("token");
      const headers = {
        "x-auth-token": token,
        "Content-Type": "application/json",
      };
      const response = await axios.get(
        "https://www.backend.luxurybubblebasket.com/user/address/getAll",
        { headers }
      );
      if (response.status === 200) {
        setShippingDetails(response.data.data);
      }
      const defaultAddress = response.data.data.find(
        (address) => address.setAsDefault
      );
      if (defaultAddress) {
        setSelectedAddressId(defaultAddress._id);
      }
    } catch (error) {
      console.error("Error fetching shipping details:", error);
    }
  };

  useEffect(() => {
    const isAddressValid = shippingDetails.some(
      (address) => address.setAsDefault
    );
    setIsProceedEnabled(isAddressValid);
  }, [shippingDetails]);

  const handleShowCouponField = () => {
    setShowCouponField(!showCouponField);
  };

  const handleCouponCheck = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(
        "https://www.backend.luxurybubblebasket.com/gust/gustPromo",
        {
          promoCode: coupon,
          totalPrice: totalPrice,
        }
      );
      if (!response.data.status) {
        setCouponError("Invalid coupon code.");
      } else {
        const couponDiscount = response.data.couponDiscount || 0;
        const updatedTotalPrice = totalPrice - couponDiscount;
        setPromoDiscount(couponDiscount);
        setTotalPrice(updatedTotalPrice.toFixed(2));

        // Store promo code, total price, and discount in sessionStorage
        sessionStorage.setItem("promoCode", coupon);
        sessionStorage.setItem("totalPrice", updatedTotalPrice.toFixed(2));
        sessionStorage.setItem("couponDiscount", couponDiscount.toFixed(2));
        alert("Promo code applied successfully!");
        setCouponError("");
      }
    } catch (error) {
      console.log(error);
      setCouponError("Invalid coupon code.");
    }
  };

  const handleProceedToPayment = () => {
    const shippingData = JSON.parse(sessionStorage.getItem("shippingDetails"));
    if (shippingData) {
      navigate("/payment-guest");
    } else {
      alert("Please add shipping address");
    }
  };

  // Tax percent
  const taxPercent = 6;

  // Calculate totals with per-product tax and discount
  const cartItemsWithTax = cartData.map((item) => {
    const itemSubtotal = item.price * item.quantity;
    const itemTax = (itemSubtotal * taxPercent) / 100;
    return {
      ...item,
      itemSubtotal: itemSubtotal.toFixed(2),
      itemTax: itemTax.toFixed(2),
      itemTotal: (itemSubtotal + itemTax).toFixed(2),
    };
  });

  const subtotal = cartItemsWithTax.reduce(
    (acc, item) => acc + parseFloat(item.itemSubtotal),
    0
  );

  const totalTax = cartItemsWithTax.reduce(
    (acc, item) => acc + parseFloat(item.itemTax),
    0
  );

  useEffect(() => {
    const initialTotalPrice = (subtotal + totalTax).toFixed(2);
    setTotalPrice(initialTotalPrice);
    sessionStorage.setItem("totalPrice", initialTotalPrice);
  }, [subtotal, totalTax]);

  return (
    <div className={style.main}>
      <form className={style.form} onSubmit={handleCouponCheck}>
        <div className={style.coupon_box}>
          <span>Have a coupon? </span>
          <span onClick={handleShowCouponField} className={style.coupon_toggle}>
            Click here to enter your code
          </span>
        </div>

        <div
          className={style.coupon_field}
          style={{
            height: showCouponField ? "200px" : "0",
            overflow: "hidden",
            transition: "height 0.3s ease",
            marginTop: "20px",
          }}
        >
          {couponError && <span style={{ color: "red" }}>{couponError}</span>}
          <div>
            <label htmlFor="coupon">
              If you have a coupon code, please apply it below.
            </label>
            <input
              style={{ fontSize: "16px" }}
              type="text"
              id="coupon"
              name="coupon"
              value={coupon}
              onChange={(e) => setCoupon(e.target.value)}
              placeholder="Coupon Code"
            />
          </div>
          <button type="submit" className={style.apply_coupon_button}>
            APPLY COUPON →
          </button>
        </div>

        <br />

        <div className={style.grid_container}>
          <BillingAddressFormGuest />
          <ShippingAddressFormGuest />
        </div>

        <br />

        <div className={style.order_notes}>
          <label htmlFor="orderNotes">Order Notes (optional)</label>
          <textarea
            id="orderNotes"
            name="orderNotes"
            rows="4"
            placeholder="Any special requests or notes for your order."
            style={{ padding: "10px" }}
          />
        </div>

        <br />

        <div className={style.order_summary}>
          <h4>YOUR ORDER</h4>
          <div>
            <div className={style.order_item}>
              <div className={style.headers}>
                <span>PRODUCT</span>
                <span className={style.subtotal_box}>SUBTOTAL</span>
              </div>
            </div>

            {cartItemsWithTax.map((item, index) => (
              <div key={index} className={style.order_item}>
                <div className={style.product_item}>
                  <span>
                    {item?.title} x <strong>{item?.quantity}</strong>
                  </span>
                  <span className={style.calculate_}>
                     ${item.itemSubtotal} <br />
                  </span>
                </div>
              </div>
            ))}

            <div className={style.order_item}>
              <div className={style.product_item}>
                <span>SUBTOTAL</span>
                <span className={style.calculate_}>${subtotal.toFixed(2)}</span>
              </div>
            </div>

            <div className={style.order_item}>
              <div className={style.product_item}>
                <span>Total Tax ({taxPercent}%):</span>
                <span className={style.calculate_}>${totalTax.toFixed(2)}</span>
              </div>
            </div>

            {promoDiscount > 0 && (
              <div className={style.order_item}>
                <div className={style.product_item}>
                  <span>Coupon Discount:</span>
                  <span className={style.calculate_}>-${promoDiscount.toFixed(2)}</span>
                </div>
              </div>
            )}

            <div className={style.order_item}>
              <div className={style.product_item}>
                <strong>
                  <span>TOTAL</span>
                </strong>
                <strong className={style.calculate_}>
                  <span>${totalPrice}</span>
                </strong>
              </div>
            </div>
          </div>
          <br />
        </div>

        <br />

        <div className={style.payment_box}>
          <label>Online Payment</label>
          <button type="button" onClick={handleProceedToPayment}>
            Proceed to Payment
          </button>
        </div>
      </form>
    </div>
  );
}

export default CheckoutPageSectionSecondGuest;
